<template>
  <div class="v-d-flex v-flex-column v-contact-info">
    <div
      v-if="!hideTitle && !stringIsNullOrWhitespace(contact.Name)"
      class="v-font-weight-600 v-mb-sm"
      v-html="sanitize(contact.Name)"
    />
    <div
      v-if="!stringIsNullOrWhitespace(contact.WorkTime)"
      class="v-d-flex v-mb-xs v-cursor-default"
    >
      <icon-general-time class="v-contact-info--icon v-mr-xs" />
      <div class="v-contact-info--after-icon">
        <span v-html="sanitize(contact.WorkTime)" />
      </div>
    </div>
    <div
      v-if="!stringIsNullOrWhitespace(contact.ContactEmail)"
      class="v-d-flex v-mb-xs v-flex-row"
    >
      <icon-general-mail class="v-contact-info--icon v-mr-xs" />
      <div class="v-contact-info--after-icon">
        <div
          v-for="(email, index) in contactEmails"
          :key="`contact-${contact.ID}-email-${index}`"
          class="v-mr-xs"
        >
          <arora-nuxt-link
            class-name="v-pointer v-contact-info--phone"
            external-link
            :href="`mailto:+${sanitize(email)}`"
            :label="sanitize(email)"
          />

          <span
            v-if="index < contactEmails.length - 1"
            v-html="','"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!stringIsNullOrWhitespace(contact.ContactPhone)"
      class="v-d-flex v-flex-row"
    >
      <icon-general-phone-call class="v-contact-info--icon v-mr-xs" />
      <div class="v-contact-info--after-icon">
        <div
          v-for="(phone, index) in contactPhones"
          :key="`contact-${contact.ID}-phone-${index}`"
          class="v-mr-xs"
        >
          <arora-nuxt-link
            class-name="v-pointer v-contact-info--phone"
            external-link
            :href="`tel:+${phoneBeautified(sanitize(phone)).replace(/\D+/g, '')}`"
            :label="phoneBeautified(sanitize(phone), 'other')"
          />
          <span
            v-if="index < contactPhones.length - 1"
            v-html="','"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PartialContact } from '~types/pageStore'

import { useCommon } from '@arora/common'
const { contact } = defineProps<{
  hideTitle?: boolean
  contact: PartialContact
}>()

const { sanitize } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { phoneBeautified } = usePhoneNumber()

const contactPhones = computed(() => contact.ContactPhone.split(','))
const contactEmails = computed(() => contact.ContactEmail.split(','))
</script>

<style lang="scss">
@use '~/assets/variables';

.v-contact-info {
  a,
  a:link,
  a:active,
  a:visited {
    color: variables.$BodyTextColor;
  }

  &--phone {
    text-wrap: nowrap;
  }

  &--icon {
    width: 1rem;
    height: 1rem;

    flex: 0 0 1rem;
    min-width: 1rem;
    min-height: 1rem;

    fill: none;
    color: variables.$BodyTextColorLight;
  }

  &--after-icon {
    flex: 0 0 calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem);
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
