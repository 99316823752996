<i18n>
ru:
  chipsTitle: Контакты
  restaurants: Наши рестораны
  onMap: На карте
ua:
  chipsTitle: Контакти
  restaurants: Наші ресторани
  onMap: На мапі
us:
  chipsTitle: Contacts
  restaurants: Our restaurants
  onMap: On the map
</i18n>

<template>
  <div class="v-contacts-list v-mb-xl">
    <template v-if="pageStore.Contacts.state === 'success' && pageStore.Contacts.data">
      <div
        class="v-contacts-list--title v-title v-mb-md"
        v-html="translate('contacts.restaurants')"
      />
      <common-cards-flex-mesh
        :items="contacts"
        :max-items="3"
      >
        <template #item="item: Contact">
          <div class="v-contact v-d-flex v-flex-column">
            <span
              class="v-contact--title v-mb-xs"
              v-html="capitalLetter(sanitize(item.Name))"
            />
            <template v-if="!stringIsNullOrWhitespace(item.Address)">
              <div
                v-if="stringIsNullOrWhitespace(item.AddressMapOrganisation)"
                class="v-contact--has-vertical-line v-d-flex v-pb-sm v-mb-sm"
              >
                <icon-address-mark class="v-contact--icon v-mr-xs" />
                <span
                  class="v-contact--address"
                  v-html="sanitize(item.Address)"
                />
              </div>
              <div
                v-else
                class="v-contact--has-vertical-line v-d-flex v-pb-sm v-mb-sm"
              >
                <arora-nuxt-link
                  class-name="v-mb-xxs v-pointer v-contact-info--link"
                  external-link
                  open-in-new-tab
                  :href="`https://yandex.ru/maps/org/${clean(item.AddressMapOrganisation)}`"
                  :label="sanitize(item.Address)"
                >
                  <icon-address-mark class="v-contact--icon v-mr-xs" />
                  <span
                    class="v-contact--address"
                    v-html="sanitize(item.Address)"
                  />
                </arora-nuxt-link>
              </div>
            </template>
            <div
              v-if="
                !stringIsNullOrWhitespace(item.ContactPhone) ||
                !stringIsNullOrWhitespace(item.ContactEmail) ||
                !stringIsNullOrWhitespace(item.WorkTime)
              "
              :class="{
                'v-pb-sm v-mb-sm v-contact--has-vertical-line': item.Contacts.some((filterContact) => {
                  return (
                    !stringIsNullOrWhitespace(filterContact.Name) ||
                    !stringIsNullOrWhitespace(filterContact.ContactPhone) ||
                    !stringIsNullOrWhitespace(filterContact.ContactEmail) ||
                    !stringIsNullOrWhitespace(filterContact.WorkTime)
                  )
                })
              }"
            >
              <page-contacts-info-part
                hide-title
                :contact="item"
              />
            </div>
            <div
              v-for="(contact, index) in item.Contacts.filter((filterContact) => {
                return (
                  !stringIsNullOrWhitespace(filterContact.Name) ||
                  !stringIsNullOrWhitespace(filterContact.ContactPhone) ||
                  !stringIsNullOrWhitespace(filterContact.ContactEmail) ||
                  !stringIsNullOrWhitespace(filterContact.WorkTime)
                )
              })"
              :key="contact.ID"
              :class="{
                'v-pb-sm v-mb-sm v-contact--has-vertical-line': index !== item.Contacts.length - 1
              }"
            >
              <page-contacts-info-part :contact="contact" />
            </div>
          </div>
        </template>
      </common-cards-flex-mesh>
      <template v-if="appConfig.VueSettingsPreRun.ContactShowMap">
        <h2 v-html="translate('contacts.onMap')" />
        <maps-connector :file-name="appConfig.VueSettingsPreRun.ContactMapFile" />
      </template>
    </template>
    <arora-loader v-else />
  </div>
</template>

<script setup lang="ts">
import type { Contact } from '~types/pageStore'

import { useCommon } from '@arora/common'

const appConfig = useAppConfig()
const pageStore = usePageStore()

const { clean, translate, sanitize } = useI18nSanitized()
const { capitalLetter, stringIsNullOrWhitespace } = useCommon()

const contacts = computed<Contact[]>(() =>
  (pageStore.Contacts.data?.Contacts ?? [])
    .filter((c) => c.Active)
    .sort((a, b) => {
      return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1
    })
)

onMounted(() => {
  pageStore.initContacts()
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-contacts-list {
  &--title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
  }

  .v-contact {
    padding: 20px;

    &--title {
      color: variables.$PrimaryBackgroundColor;
      font-size: 1.145rem;
      font-weight: 600;
    }

    &--has-vertical-line {
      min-height: 32px;
      border-bottom: 2px solid rgba(125, 125, 125, 0.1);
    }

    &--address {
      color: variables.$PrimaryBackgroundColor;
    }

    &--icon {
      color: variables.$PrimaryBackgroundColor;
      width: 16px;
      height: 16px;

      min-width: 16px;
      min-height: 16px;

      fill: none;
    }

    &--description {
      hr {
        color: rgba(125, 125, 125, 0.1);
      }
    }
  }
}
</style>
